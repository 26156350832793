@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: var(--novel-white);
}

@page { 
  margin-top: 0; 
  margin-bottom: 0; 
}

@media print { 
  body * {
      visibility: hidden;
  }
  .print-area , 
  .print-area  * {
      visibility: visible;
  }
  .print-area  {
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 12px; 
      padding-bottom: 12px;
  }
  .sm\:novel-shadow-lg {
    box-shadow: none !important;
}
  .sm\:novel-border {
    border: none !important;
}
  p[data-placeholder="Press '/' for commands"].is-empty {
    display: none !important;
}
}

